// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const blockchain = store.getState().blockchain;
      let totalSupply = await store
        .getState()
        .blockchain.nftSmartContract.methods.totalSupply()
        .call();
      let paused = await store
        .getState()
        .blockchain.smartContract.methods.paused()
        .call();
      let buyCount = await store
        .getState()
        .blockchain.smartContract.methods.getBuyCount(blockchain.userId)
        .call();
      let mintedAmount = await store
        .getState()
        .blockchain.nftSmartContract.methods.balanceOf(blockchain.account)
        .call();
      let maxSupply = await store
        .getState()
        .blockchain.smartContract.methods.maxSupply()
        .call();
      let currentSaleData = await store
        .getState()
        .blockchain.smartContract.methods.getCurrentSale()
        .call();

      dispatch(
        fetchDataSuccess({
          paused,
          totalSupply,
          buyCount,
          mintedAmount,
          maxSupply,
          currentSaleData,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(
        fetchDataFailed(
          <>
            コントラクトからデータを読み込むことができませんでした。
            <br />
            Could not read data from contract.
          </>
        )
      );
    }
  };
};
