const initialState = {
  loading: false,
  account: null,
  smartContract: null,
  nftSmartContract: null,
  web3: null,
  userId: 0,
  isAllowlisted: false,
  merkleproof: "",
  allowedAmount: 0,
  errorMsg: "",
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        smartContract: action.payload.smartContract,
        nftSmartContract: action.payload.nftSmartContract,
        web3: action.payload.web3,
        userId: action.payload.userId,
        isAllowlisted: action.payload.isAllowlisted,
        merkleproof: action.payload.merkleproof,
        allowedAmount: action.payload.allowedAmount,
        gasPrice: action.payload.gasPrice,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    default:
      return state;
  }
};

export default blockchainReducer;
