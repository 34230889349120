// constants
import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
// log
import { fetchData } from "../data/dataActions";
import { MerkleTree } from "merkletreejs";
import { keccak256 } from "@ethersproject/keccak256";
import { ethers } from "ethers";
import allowListWithMultiWallet from "./allowlist";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/sale_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();

    const nftAbiResponse = await fetch("/config/nft_abi.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const nftAbi = await nftAbiResponse.json();

    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const CONFIG = await configResponse.json();
    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;

    if (metamaskIsInstalled) {
      Web3EthContract.setProvider(ethereum);
      let web3 = new Web3(ethereum);
      await ethereum.request({
        method: "wallet_switchEthereumChain",
        // params: [{ chainId: "0x5" }],
        params: [{ chainId: "0x1" }],
      });
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        console.log(accounts[0]);
        const networkId = await ethereum.request({
          method: "net_version",
        });
        if (networkId == CONFIG.NETWORK.ID) {
          const gasPrice = await web3.eth.getGasPrice();
          const SmartContractObj = new Web3EthContract(
            abi,
            CONFIG.SALE_CONTRACT_ADDRESS
          );
          const NftSmartContractObj = new Web3EthContract(
            nftAbi,
            CONFIG.NFT_CONTRACT_ADDRESS
          );

          //MerkleTree
          const createTreeMultiWalet = (allowListWithMultiWallet) => {
            const leaves = allowListWithMultiWallet.map((node) =>
              ethers.utils.solidityKeccak256(
                ["uint256", "address", "uint256"],
                [node.userId, node.address, node.allowedAmount]
              )
            );
            return new MerkleTree(leaves, keccak256, { sortPairs: true });
          };

          const merkleTreeWithMultiWallet = createTreeMultiWalet(
            allowListWithMultiWallet
          );

          const rootHashWithMultiWallet =
            merkleTreeWithMultiWallet.getHexRoot();

          const clamingAddress = String(accounts[0]).toLowerCase();
          const clamingUser = allowListWithMultiWallet.find(
            (u) => u.address === clamingAddress
          );
          if (clamingUser) {
            var clamingUserID = clamingUser.userId;

            var clamingUsersAllowedAmount = clamingUser.allowedAmount;
            console.log("your allowed amount: ", clamingUsersAllowedAmount);

            let clamingAddressesHash = ethers.utils.solidityKeccak256(
              ["uint256", "address", "uint256"],
              [clamingUserID, clamingAddress, clamingUsersAllowedAmount]
            );

            var hexProof =
              merkleTreeWithMultiWallet.getHexProof(clamingAddressesHash);

            var allowlisted = merkleTreeWithMultiWallet.verify(
              hexProof,
              clamingAddressesHash,
              rootHashWithMultiWallet
            );
            console.log("Allowlisted: ", allowlisted);
          } else {
            console.log("Sorry, not allowlisted");
            clamingUserID = 0;
            clamingUsersAllowedAmount = 0;
            allowlisted = false;
          }

          dispatch(
            connectSuccess({
              account: accounts[0],
              smartContract: SmartContractObj,
              nftSmartContract: NftSmartContractObj,
              web3: web3,
              userId: clamingUserID,
              isAllowlisted: allowlisted,
              merkleproof: hexProof,
              allowedAmount: clamingUsersAllowedAmount,
              gasPrice: gasPrice,
            })
          );
          // Add listeners start
          ethereum.on("accountsChanged", (accounts) => {
            dispatch(updateAccount(accounts[0]));
          });
          ethereum.on("chainChanged", () => {
            window.location.reload();
          });
          // Add listeners end
        } else {
          dispatch(
            connectFailed(
              <>
                設定を {CONFIG.NETWORK.NAME} network に変更してください。
                <br />
                Change the setting to {CONFIG.NETWORK.NAME} network.
              </>
            )
          );
        }
      } catch (err) {
        dispatch(
          connectFailed(
            <>
              すみません。何かがおかしいです。
              <br />
              I'm sorry. something is wrong
            </>
          )
        );
      }
    } else {
      dispatch(
        connectFailed(
          <>
            メタマスクをインストールしてください。
            <br />
            Please install metamask.
          </>
        )
      );
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
    dispatch(fetchData(account));
  };
};
