import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: 2px ridge var(--secondary);
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: var(--secondary-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: 2px ridge var(--secondary);
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  color: var(--primary-text);
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  background-color: var(--accent);
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [feedback, setFeedback] = useState();
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    SALE_CONTRACT_ADDRESS: "",
    NFT_CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .claim(
        blockchain.userId,
        mintAmount,
        blockchain.allowedAmount,
        blockchain.merkleproof
      )
      .send({
        // gasLimit: String(totalGasLimit), //ERC721Aの場合はコメントアウト
        to: CONFIG.SALE_CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
        // gasPrice: blockchain.gasPrice * mintAmount, //ERC721Aの場合はコメントアウト
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > blockchain.allowedAmount - data.buyCount) {
      newMintAmount = blockchain.allowedAmount - data.buyCount;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      return new Promise((resolve, reject) => {
        dispatch(fetchData(blockchain.account));
        setTimeout(() => {
          setIsLoading(false);
          resolve();
        }, 3000);
      });
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    const config = await configResponse.json();
    console.log(config);
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  const connectStart = async () => {
    dispatch(connect());
    await getData();
  };

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerSmall />
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/oto.png"} />
          </s.Container>
          <s.SpacerLarge />
          <s.Container
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "red",
                fontSize: 20,
              }}
            >
              ⛩ {CONFIG.NFT_NAME} ⛩
            </s.TextDescription>
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 50,
                fontWeight: "bold",
                color: "var(--accent-text)",
              }}
            >
              {data.totalSupply} / {data.maxSupply}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.NFT_CONTRACT_ADDRESS, 15)}
              </StyledLink>
              <br />
              <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                {CONFIG.MARKETPLACE}
              </StyledLink>
            </s.TextDescription>
            <s.SpacerSmall />

            <s.TextDescription
              style={{ textAlign: "center", color: "var(--accent-text)" }}
            >
              {Number(data.totalSupply) < data.maxSupply
                ? `${CONFIG.DISPLAY_COST} ETH（+ gas）`
                : ""}
            </s.TextDescription>
            <s.SpacerSmall />
            {blockchain.account === "" || blockchain.smartContract === null ? (
              <s.Container ai={"center"} jc={"center"}>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  {CONFIG.NETWORK.NAME} network に接続してください。
                </s.TextDescription>
                <s.TextDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  Please connect {CONFIG.NETWORK.NAME} network.
                </s.TextDescription>
                <s.SpacerSmall />
                <StyledButton
                  onClick={(e) => {
                    e.preventDefault();

                    connectStart();
                  }}
                >
                  <font size="3">CONNECT</font>
                </StyledButton>
                {blockchain.errorMsg !== "" ? (
                  <>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {blockchain.errorMsg}
                    </s.TextDescription>
                  </>
                ) : null}
              </s.Container>
            ) : (
              <>
                <s.MintDescription
                  style={{
                    textAlign: "center",
                    color: "var(--accent-text)",
                  }}
                >
                  <div>
                    {isLoading ? (
                      <>
                        読み込み中・・・
                        <br />
                        Now Loading...
                      </>
                    ) : (
                      <>
                        {data.paused == true ? (
                          <>
                            <p>現在はミントできません。</p>
                            <p>ミント開始までお待ち下さい。</p>
                            <s.SpacerSmall />
                            <p>Mint is paused.</p>
                            <p>Please wait until the mint starts.</p>
                          </>
                        ) : blockchain.allowedAmount == 0 ? (
                          <>
                            アローリストがありません
                            <br />
                            "You have not allowlist"
                          </>
                        ) : Number(data.totalSupply) >= data.maxSupply ? (
                          <>
                            <s.TextDescription>
                              完売しました！
                            </s.TextDescription>
                            <s.TextDescription>
                              ありがとうございます
                            </s.TextDescription>
                            <s.TextDescription>
                              NFT is sold out. Thank you!
                            </s.TextDescription>
                          </>
                        ) : data.buyCount < blockchain.allowedAmount ? (
                          <s.StyledDescriptionList>
                            <s.StyledTeam>
                              ミントできる数 <br />
                              You can minted：
                              <s.StyledDescription>
                                {blockchain.allowedAmount}
                              </s.StyledDescription>
                            </s.StyledTeam>
                            <s.StyledTeam>
                              ミント済みの数 <br />
                              minted number：
                              <s.StyledDescription>
                                {data.buyCount}
                              </s.StyledDescription>
                            </s.StyledTeam>
                            <s.StyledTeam>
                              ミントできる残りの数 <br />
                              Remaining Mints：
                              <s.StyledDescription>
                                {blockchain.allowedAmount - data.buyCount}
                              </s.StyledDescription>
                            </s.StyledTeam>
                          </s.StyledDescriptionList>
                        ) : (
                          <>
                            あなたのミント上限に達しました
                            <br />
                            You are mined.
                          </>
                        )}
                      </>
                    )}
                  </div>
                </s.MintDescription>
                <s.SpacerMedium />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledRoundButton
                    style={{ lineHeight: 0.4 }}
                    disabled={claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      decrementMintAmount();
                    }}
                  >
                    -
                  </StyledRoundButton>
                  <s.SpacerMedium />
                  <s.TextDescription
                    style={{
                      textAlign: "center",
                      color: "var(--accent-text)",
                    }}
                  >
                    <font size="6">
                      {data.paused == true ? "0" : mintAmount}
                    </font>
                  </s.TextDescription>
                  <s.SpacerMedium />
                  <StyledRoundButton
                    disabled={data.paused == true ? 1 : claimingNft ? 1 : 0}
                    onClick={(e) => {
                      e.preventDefault();
                      incrementMintAmount();
                    }}
                  >
                    +
                  </StyledRoundButton>
                </s.Container>
                <s.SpacerSmall />
                <s.Container ai={"center"} jc={"center"} fd={"row"}>
                  <StyledButton
                    disabled={
                      claimingNft
                        ? 1
                        : data.paused == true ||
                          blockchain.isAllowlisted == false
                        ? 1
                        : data.totalSupply >= CONFIG.MAX_SUPPLY ||
                          data.buyCount >= blockchain.allowedAmount
                        ? 1
                        : 0
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      claimNFTs();
                      getData();
                    }}
                  >
                    <font size="3">
                      {claimingNft
                        ? "BUSY"
                        : data.paused == true ||
                          blockchain.isAllowlisted == false ||
                          data.totalSupply >= CONFIG.MAX_SUPPLY
                        ? "STOP"
                        : data.buyCount >= blockchain.allowedAmount
                        ? "STOP"
                        : "MINT"}
                    </font>
                  </StyledButton>
                </s.Container>
              </>
            )}

            <s.SpacerMedium />
          </s.Container>
          <s.SpacerLarge />
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <StyledImg alt={"example"} src={"/config/images/uka.png"} />
          </s.Container>
        </ResponsiveWrapper>
        <s.SpacerMedium />
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            正しいネットワークに接続されているか確認してください(
            {CONFIG.NETWORK.NAME} Mainnet)。
            <br />
            Please confirm you connect to {CONFIG.NETWORK.NAME} Mainnet.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
            }}
          >
            ガス代が低すぎると失敗することがあります。適切なガス代を設定してください。
            <br />
            Please set appropriate Gas fee for mint.
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default App;
